module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"actions":{"primary":"#308AC1"},"palette":{"darkAccent":"#112F42","darkShades":"#333447","lightAccent":"#70A9CC","lightShades":"#E8E8E8","mainBrand":"#308AC1"},"global":{"background":"#E8E8E8","backgroundGradient":"linear-gradient(to bottom,rgb(239 233 233 / 90%), rgb(232 232 232))","color":"#232323","grey":"#585858","link":"#70A9CC","linkHover":"#308AC1"}},"dark":{"actions":{"primary":"#308AC1"},"palette":{"darkAccent":"#112F42","darkShades":"#333447","lightAccent":"#70A9CC","lightShades":"#E8E8E8","mainBrand":"#308AC1"},"global":{"background":"#112F42","backgroundGradient":"linear-gradient(to bottom,rgb(13 37 51 / 90%), rgb(17 47 66))","color":"#fff","grey":"#E8E8E8","link":"#70A9CC","linkHover":"#308AC1"}}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#000","theme_color":"#308AC1","display":"minimal-ui","icon":"src/assets/img/portfolio-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4eac890ac4804bd1324f207f776e7a47"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
